import React, { useCallback, useContext, useEffect, useState } from "react"
import { arrayOf, bool, number, shape, string } from "prop-types"
import { Collapse } from "react-bootstrap"

import SidebarContext from "src/features/Sidebar/SidebarContext"
import { getCurrentMainSchemeId, getCurrentMeetingPackId } from "src/helpers/document"
import { isAccountManagePage } from "src/helpers/routes"
import Icon from "src/styles/components/Icon"

import { addBadgeToTheScheme } from "./helpers"
import MeetingPack from "./MeetingPack"
import Scheme from "./Scheme"
import AccountName from "./AccountName"
import { AddMeetingPackLink, Divider, SubSectionTitle } from "./styles"

const hiddenAccountSectionKey = (id) => {
  return `hidden-account-sidebar-section-${id}`
}

const Account = ({ account }) => {
  const currentMeetingPackId = getCurrentMeetingPackId()
  const currentMainSchemeId = getCurrentMainSchemeId()
  const current = isAccountManagePage(window.location.pathname, account.id)

  const { stats, syncStats } = useContext(SidebarContext)

  const meetingPacks = account?.meetingPacks
  const schemes = account?.schemes?.map((scheme) => addBadgeToTheScheme(scheme, stats))

  const hasMeetings = !!meetingPacks?.length
  const hasSchemes = !!schemes?.length

  const hasUpdatedMeetings = meetingPacks?.some((meetingPack) => !!meetingPack?.unread)
  const hasUpdatesInSchemes = schemes.some(({ badge }) => Boolean(badge))

  const hasUpdates = hasUpdatedMeetings || hasUpdatesInSchemes

  const [isAccountOpened, setIsAccountOpened] = useState(
    !localStorage.getItem(hiddenAccountSectionKey(account.id)) || hasUpdates
  )

  useEffect(() => {
    if (hasUpdates) {
      setIsAccountOpened(true)
    }
  }, [hasUpdates])

  const toggleAccountOpened = useCallback(
    (e) => {
      // don't collapse list when follow the link
      if (e.target?.href) {
        return
      }

      const willAccountBeOpened = !isAccountOpened

      if (willAccountBeOpened) {
        localStorage.removeItem(hiddenAccountSectionKey(account.id))
      } else {
        localStorage.setItem(hiddenAccountSectionKey(account.id), "true")
      }

      setIsAccountOpened(willAccountBeOpened)
    },
    [isAccountOpened]
  )

  return (
    <div
      className="sidebar-subsection"
      data-testid="account-sidebar-section"
      data-id={account.id}
    >
      <Divider />
      <AccountName
        account={account}
        isAccountOpened={isAccountOpened}
        current={current}
        onClick={toggleAccountOpened}
      />

      <Collapse in={isAccountOpened}>
        <div>
          {(hasMeetings || account.canCreateMeetingPack) && (
            <>
              <SubSectionTitle className="sidebar-subsection-title">
                Upcoming Meetings
                {account.canCreateMeetingPack && (
                  <AddMeetingPackLink href={account.newMeetingPath}>
                    <Icon type="add" /> Add
                  </AddMeetingPackLink>
                )}
              </SubSectionTitle>
              {meetingPacks.map((meetingPack) => (
                <MeetingPack
                  key={meetingPack.id}
                  current={meetingPack.id === currentMeetingPackId}
                  syncStats={syncStats[meetingPack.id]}
                  {...meetingPack}
                />
              ))}
            </>
          )}

          {hasSchemes && (
            <>
              <SubSectionTitle className="sidebar-subsection-title with-padding">
                Collaboration spaces
              </SubSectionTitle>
              {schemes.map((scheme) => (
                <Scheme
                  key={scheme.id}
                  current={scheme.id === currentMainSchemeId}
                  {...scheme}
                />
              ))}
            </>
          )}
        </div>
      </Collapse>
    </div>
  )
}

Account.propTypes = {
  account: shape({
    id: number.isRequired,
    name: string.isRequired,
    newMeetingPath: string.isRequired,
    managePath: string.isRequired,
    canManage: bool.isRequired,
    meetingPacks: arrayOf(
      shape({
        id: number.isRequired,
        name: string.isRequired,
        path: string.isRequired
      })
    ),
    schemes: arrayOf(
      shape({
        id: number.isRequired,
        name: string.isRequired,
        path: string.isRequired
      })
    )
  })
}

export default Account
