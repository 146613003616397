import { styled } from "@linaria/react"

import {
  activeBackgroundColor,
  hoverBackgroundColor,
  titleColor
} from "src/features/Sidebar/styles"
import UnstyledButton from "src/styles/components/Button/Unstyled"

export const Container = styled.span`
  position: relative; // Position is relative to keep the width of dropdown the same as container
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  height: 32px;
  padding: 6px 16px 6px 0;
  margin: 0 -7px;

  .icon-tridot {
    transform: rotate(90deg);
  }

  &:hover {
    border-radius: 6px;
    background: ${hoverBackgroundColor};
  }

  &.current {
    border-radius: 6px;
    background: ${activeBackgroundColor};
  }

  .dropdown {
    // Position is static to skip this parent node when we count position of absolute element
    position: static !important;
  }

  .dropdown-menu {
    right: 5px !important;
    left: -5px !important;
    top: 10px !important;
    border-radius: 6px;
    background: #f5edfb;
    box-shadow: 0 25px 50px -12px rgba(0, 0, 0, 0.25);
    padding: 0;
    color: #181023;
    font-size: 14px;

    .dropdown-item:hover {
      border-radius: 6px;
      background: #dec6f3;
      box-shadow: 0 25px 50px -12px rgba(0, 0, 0, 0.25);
    }

    .dropdown-item:focus {
      color: #181023;
    }
  }
`

export const Button = styled(UnstyledButton)`
  color: white;
  display: flex;
  justify-content: space-between;
  align-items: center;

  overflow-x: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;

  .icon-dropdown-arrow {
    color: ${titleColor};
    margin-right: 5px;
  }

  &.collapsed .icon-dropdown-arrow {
    transform: rotate(270deg);
  }
`
