import { Application, Controller } from "@hotwired/stimulus"
import ReactDOM from "react-dom"
import React from "react"

import { withApolloProvider } from "src/app/apollo"
import { withRouterProvider } from "src/app/router"
import { withProvider } from "src/app/store"
import {
  ActionDetails,
  NewMessageActionDetails
} from "src/features/Messages/ActionDetails"
import AgendaItem from "src/features/meetingPacks/AgendaItem"
import AgendaItemStatus from "src/features/meetingPacks/MeetingPack/AgendaItemStatus"
import AirtableApp from "src/features/AirtableApp"
import IframeApp from "src/features/IframeApp"
import AirtableAppForm from "src/features/AirtableApp/Form"
import AllowAttendeesToExport from "src/features/meetingPacks/MeetingPack/AllowAttendeesToExport"
import AttachedDocumentPreview from "src/features/groups/discussions/AttachedDocumentPreview"
import AttachedSignedDocument from "src/features/signedDocuments/AttachedSignedDocument"
import AttendeesList from "src/features/meetingPacks/MeetingPack/Attendees/List"
import CollaborativeDocumentPreview from "src/features/collaborativeDocuments/CollaborativeDocumentPreview"
import CollaborativeDocuments from "src/features/collaborativeDocuments/CollaborativeDocuments"
import CustomTermsOpenedModal from "src/components/CustomTermsModal/OpenedModal"
import CustomTermsReviewButton from "src/components/CustomTermsModal/ReviewButton"
import DiscussionDrawer from "src/features/Messages/Drawer"
import DispatchAttendeesChanges from "src/features/meetingPacks/MeetingPack/Attendees/List/DispatchAttendeesChanges"
import DocumentPreview from "src/features/DocumentPreview/DocumentPreview"
import DocumentPreviewLink from "src/components/DocumentPreviewLink"
import DocumentPreviewForNewAttachment from "src/features/groups/discussions/DocumentPreviewForNewAttachment"
import DocumentPreviewFromQuery from "src/features/groups/discussions/DocumentPreviewFromQuery"
import DocumentsExplorer from "src/features/groups/DocumentsExplorer"
import DoubleLineHeader from "src/styles/components/DoubleLineHeader"
import FroalaAiButton from "src/components/FroalaAiButton"
import GroupForm from "src/features/schemes/GroupForm"
import Header from "src/features/Header"
import IconWithTooltip from "src/styles/components/IconWithTooltip"
import QueryIframeContent from "src/features/QueryIframeContent"
import InstallAppModal from "src/components/InstallAppModal"
import ManageAccount from "src/features/ManageAccount"
import ManageAccountMainMenu from "src/features/ManageAccount/MainMenu"
import ManageMembers from "src/features/groups/ManageMembers"
import MeetingAttendeesTable from "src/features/meetingPacks/AttendeesTable"
import MeetingPackAddFromOtherMeetingsButton from "src/features/meetingPacks/AttendeesTable/AddFromOtherMeetingsButton"
import MeetingPackAttendees from "src/features/meetingPacks/MeetingPack/Attendees"
import MeetingPackExport from "src/features/meetingPacks/MeetingPack/Export"
import MeetingPackHeaderLeftSide from "src/features/meetingPacks/MeetingPack/Header/LeftSide"
import MeetingPackHeaderRightSide from "src/features/meetingPacks/MeetingPack/Header/RightSide"
import MeetingPackPublish from "src/features/meetingPacks/MeetingPack/Publish"
import MeetingPackState from "src/features/meetingPacks/MeetingPackState"
import MeetingPackStepOneForm from "src/features/meetingPacks/StepOneForm"
import MeetingPackTag from "src/features/meetingPacks/MeetingPack/Tag"
import MeetingPackView from "src/features/meetingPacks/MeetingPack/View"
import Meetings from "src/features/Home/Meetings"
import Mentions from "src/features/Home/Mentions"
import MessageActionSummary from "src/features/Messages/ActionDetails/MessageActionSummary"
import MessageSelection from "src/features/Messages/Print/MessageSelection"
import MinutesAgendaExport from "src/features/meetingPacks/MeetingPack/MinutesAgendaExport"
import MoveDiscussion from "src/features/MoveDiscussion"
import MoveGroup from "src/features/MoveGroup"
import OpenaiActionsDiscussion from "src/features/OpenaiActionsDiscussion"
import PasskeyDeviceManagement from "src/features/Passkey/DeviceManagement"
import PrintForm from "src/features/Messages/Print/PrintForm"
import PrintMessage from "src/features/Messages/Print/PrintMessage"
import PrintMessages from "src/features/Messages/Print/PrintMessages"
import RemoveGroupActions from "src/features/RemoveGroupActions"
import SchemeAttendeesTable from "src/features/schemes/AttendeesTable"
import SchemeBadge from "src/features/Home/Messages/SchemeBadge"
import SearchNavIcon from "src/features/Header/HeaderNav/SearchNavIcon"
import SearchPage from "src/features/Search/SearchPage"
import SharedMessageContent from "src/features/Home/Messages/SharedMessageContent"
import ShareMessage from "src/features/ShareMessage"
import Sidebar from "src/features/Sidebar"
import SidebarToggle from "src/features/Header/SidebarToggle"
import SignedDocuments from "src/features/signedDocuments/SignedDocuments"
import StarredItems from "src/features/Home/StarredItems"
import MessageAiActions from "src/features/Messages/MessageAiActions"
import TextWithTooltip from "src/styles/components/TextWithTooltip"
import TranscriptView from "src/features/meetingPacks/MeetingPack/Transcript/index"
import TwoFactorAuthForm from "src/features/TwoFactor/AuthForm"
import TwoFactorEnablePage from "src/features/TwoFactor/Enable/Page"
import TwoFactorEnableSection from "src/features/TwoFactor/Enable/Section"
import { Sidebar as UniversalAiSidebar } from "src/features/UniversalAi"

const exposedComponents = {
  ActionDetails: withProvider(ActionDetails),
  AgendaItem: withApolloProvider(withProvider(AgendaItem)),
  AgendaItemStatus: withProvider(AgendaItemStatus),
  AirtableAppForm: withApolloProvider(withProvider(AirtableAppForm)),
  AttachedDocumentPreview: withProvider(AttachedDocumentPreview),
  AttachedSignedDocument: withApolloProvider(AttachedSignedDocument),
  AttendeesList: withProvider(AttendeesList),
  DiscussionDrawer: withApolloProvider(withProvider(DiscussionDrawer)),
  DispatchAttendeesChanges: withProvider(DispatchAttendeesChanges),
  DocumentPreview: withProvider(DocumentPreview),
  DocumentPreviewLink: withProvider(DocumentPreviewLink),
  DocumentPreviewForNewAttachment: withProvider(DocumentPreviewForNewAttachment),
  DocumentPreviewFromQuery: withProvider(withApolloProvider(DocumentPreviewFromQuery)),
  DocumentsExplorer: withProvider(withApolloProvider(DocumentsExplorer)),
  DoubleLineHeader: withProvider(DoubleLineHeader),
  FroalaAiButton: withProvider(withApolloProvider(FroalaAiButton)),
  GroupForm: withApolloProvider(GroupForm),
  Header: withProvider(withApolloProvider(Header)),
  ManageAccount: withRouterProvider(withApolloProvider(ManageAccount)),
  ManageAccountMainMenu: withProvider(ManageAccountMainMenu),
  ManageMembers: withApolloProvider(ManageMembers),
  MeetingAttendeesTable: withApolloProvider(MeetingAttendeesTable),
  MeetingPackAddFromOtherMeetingsButton: withApolloProvider(
    MeetingPackAddFromOtherMeetingsButton
  ),
  MeetingPackAttendees: withProvider(MeetingPackAttendees),
  MeetingPackExport: withApolloProvider(MeetingPackExport),
  MeetingPackHeaderLeftSide: withProvider(MeetingPackHeaderLeftSide),
  MeetingPackHeaderRightSide: withProvider(MeetingPackHeaderRightSide),
  MeetingPackPublish: withProvider(withApolloProvider(MeetingPackPublish)),
  MeetingPackState: withProvider(MeetingPackState),
  MeetingPackStepOneForm: withApolloProvider(MeetingPackStepOneForm),
  MeetingPackTag: withProvider(MeetingPackTag),
  MeetingPackView: withProvider(withApolloProvider(MeetingPackView)),
  Meetings: withApolloProvider(withProvider(Meetings)),
  Mentions: withProvider(Mentions),
  MessageSelection: withProvider(MessageSelection),
  MinutesAgendaExport: withApolloProvider(MinutesAgendaExport),
  MoveDiscussion: withProvider(MoveDiscussion),
  MoveGroup: withProvider(MoveGroup),
  NewMessageActionDetails: withProvider(NewMessageActionDetails),
  OpenaiActionsDiscussion: withProvider(withApolloProvider(OpenaiActionsDiscussion)),
  PasskeyDeviceManagement: withApolloProvider(PasskeyDeviceManagement),
  PrintForm: withProvider(PrintForm),
  PrintMessage: withProvider(PrintMessage),
  PrintMessages: withProvider(PrintMessages),
  RemoveGroupActions: withApolloProvider(RemoveGroupActions),
  QueryIframeContent: withProvider(withApolloProvider(QueryIframeContent)),
  SearchNavIcon: withApolloProvider(SearchNavIcon),
  SearchPage: withProvider(withApolloProvider(SearchPage)),
  ShareMessage: withProvider(ShareMessage),
  Sidebar: withApolloProvider(Sidebar),
  SidebarToggle: withProvider(SidebarToggle),
  StarredItems: withProvider(StarredItems),
  MessageAiActions: withProvider(withApolloProvider(MessageAiActions)),
  TranscriptView: withProvider(withApolloProvider(TranscriptView)),
  UniversalAiSidebar: withProvider(withApolloProvider(UniversalAiSidebar)),
  AirtableApp,
  AllowAttendeesToExport,
  CollaborativeDocumentPreview,
  CollaborativeDocuments,
  CustomTermsOpenedModal,
  CustomTermsReviewButton,
  IconWithTooltip,
  IframeApp,
  InstallAppModal,
  MessageActionSummary,
  SchemeAttendeesTable,
  SchemeBadge,
  SharedMessageContent,
  SignedDocuments,
  TextWithTooltip,
  TwoFactorAuthForm,
  TwoFactorEnablePage,
  TwoFactorEnableSection
}

class ReactController extends Controller {
  initialize() {
    this.elementData = this.element.dataset
  }

  connect() {
    const component = this.getComponent()

    ReactDOM.render(React.createElement(component, this.componentProps()), this.element)
  }

  disconnect() {
    ReactDOM.unmountComponentAtNode(this.element)
  }

  getComponent() {
    if (!this.elementData.component) {
      throw new Error(
        `You should define the exposed component name
        via 'data-component' attribute to use this controller`
      )
    }

    if (!exposedComponents[this.elementData.component]) {
      throw new Error(
        `${this.element.getAttribute(
          "data-component"
        )} wasn't registered in exposedComponents`
      )
    }

    return exposedComponents[this.elementData.component]
  }

  componentProps() {
    return JSON.parse(this.elementData.props)
  }
}

window.Stimulus = Application.start()
window.Stimulus.register("react", ReactController)
