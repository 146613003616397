import React from "react"
import { Dropdown } from "react-bootstrap"
import classNames from "classnames"
import { bool, func, shape, string } from "prop-types"

import DropdownToggle from "src/features/Sidebar/Accounts/Account/DropdownToggle"
import Icon from "src/styles/components/Icon"

import { Button, Container } from "./styles"

const AccountName = ({ account, onClick, isAccountOpened, current }) => {
  const hasItems = account.meetingPacks.length > 0 || account.schemes.length > 0

  return (
    <Container
      className={classNames("sidebar-subsection-name", { current })}
      onClick={onClick}
    >
      <Button
        className={classNames("sidebar-organization-name", {
          collapsed: !isAccountOpened
        })}
      >
        {hasItems && <Icon type="dropdown-arrow" className="no-transition" />}
        {account.name}
      </Button>

      {account.canManage && (
        <Dropdown>
          <Dropdown.Toggle as={DropdownToggle} />
          <Dropdown.Menu popperConfig={{ placement: "bottom-start" }}>
            <Dropdown.Item href={account.managePath}>Manage Organisation</Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      )}
    </Container>
  )
}

AccountName.propTypes = {
  account: shape({
    name: string.isRequired,
    newMeetingPath: string.isRequired,
    managePath: string.isRequired,
    canManage: bool.isRequired,
    canCreateMeetingPack: bool.isRequired
  }).isRequired,
  onClick: func.isRequired,
  isAccountOpened: bool.isRequired,
  current: bool.isRequired
}

export default AccountName
