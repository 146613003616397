import React, { useEffect, useMemo } from "react"
import PerfectScrollbar from "react-perfect-scrollbar"
import { useQuery } from "@apollo/client"
import { string } from "prop-types"
import { pick } from "lodash"

import useAblyChannel from "src/hooks/useAblyChannel"
import useUnreadStats from "src/hooks/useUnreadStats"
import { CHANNELS } from "src/constants/ably"
import { toggleSidebar, isMainContentNarrow } from "src/helpers/document"
import useMobileScreen from "src/hooks/useMobileScreen"
import useSyncStatus from "src/features/offline/hooks/useSyncStatus"

import { useSidebarOpened as useUniversalAiSidebarOpened } from "../UniversalAi"

import Accounts from "./Accounts"
import BaseNav from "./BaseNav"
import SidebarContext from "./SidebarContext"
import SidebarHead from "./SidebarHead"
import UserMenu from "./UserMenu"
import useFaviconUpdates from "./hooks/useFaviconUpdates"
import useMeetingPacksUpdates from "./hooks/useMeetingPacksUpdates"
import sidebarDataQuery from "./sidebarData.gql"
import { scrollToCurrentAccountItem } from "./helpers"
import { HeadContainer } from "./styles"
import Suggestions from "./Suggestions"

const MEETING_PACK_VIEW_PAGE = /\/meeting_packs\/(\d+)\/view/

const Sidebar = ({ exitPath }) => {
  const stats = useUnreadStats()
  const syncStats = useSyncStatus({})
  const sidebarContextValue = useMemo(() => ({ stats, syncStats }), [stats, syncStats])

  const { data, refetch } = useQuery(sidebarDataQuery)
  const isMobileScreen = useMobileScreen()

  useMeetingPacksUpdates()
  useFaviconUpdates(stats)
  useAblyChannel(CHANNELS.users.starredItemsStatus, {
    onMessage: refetch
  })

  useEffect(() => {
    if (data) scrollToCurrentAccountItem()
  }, [!data])

  useUniversalAiSidebarOpened(() => {
    const sidebarElement = document.querySelector("[data-behavior=sidebar]")
    const { x } = sidebarElement.getBoundingClientRect()
    if (x < 0 || !isMainContentNarrow() || isMobileScreen) return
    if (!MEETING_PACK_VIEW_PAGE.test(window.location.pathname)) return

    toggleSidebar()
  }, [isMobileScreen])

  if (!data) return null

  const { recentThreads, accounts, me } = data
  // TODO: use library to extract data from the fragments for BaseNav and UserMenu
  return (
    <SidebarContext.Provider value={sidebarContextValue}>
      <HeadContainer>
        <SidebarHead />
        <Suggestions recentThreads={recentThreads} />
        <BaseNav
          exitPath={exitPath}
          {...pick(me || {}, ["unreadItems", "starredItems", "meetingPacks"])}
        />
      </HeadContainer>
      <PerfectScrollbar data-behavior="scheme-menu-section">
        <Accounts accounts={accounts} onDataLoaded={scrollToCurrentAccountItem} />
      </PerfectScrollbar>
      <UserMenu user={me} />
    </SidebarContext.Provider>
  )
}

Sidebar.propTypes = {
  exitPath: string.isRequired
}

export default Sidebar
