import React from "react"
import { orderBy } from "lodash"
import { arrayOf, number, shape } from "prop-types"

import { SectionTitle } from "src/features/Sidebar/styles"

import Account from "./Account"
import { AccountsContainer } from "./styles"
import { Divider } from "./Account/styles"

const Accounts = ({ accounts }) => {
  if (!accounts) {
    return null
  }

  return (
    <AccountsContainer data-behavior="accounts-sidebar-section">
      <SectionTitle>Organisations</SectionTitle>

      <div className="sidebar-menu">
        {orderBy(accounts, ["name"], ["asc"]).map((account) => (
          <Account key={account.id} account={account} />
        ))}
        <Divider />
      </div>
    </AccountsContainer>
  )
}

Accounts.propTypes = {
  accounts: arrayOf(
    shape({
      id: number.isRequired
    })
  )
}

export default Accounts
